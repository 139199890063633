import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import ProvisionSiteTabs from "./ProvisionSiteTabs"
import { Footer } from "@modules/site/create/shared/components/Footer"
import { Header } from "@modules/site/create/shared/components/Header"
import PickSourceTarget from "./PickSourceTarget"
import ConnectCms from "./ConnectCms"
import Summary from "./Summary"
import PickStarter from "@modules/onboarding/gaFlow/components/PickStarter"
import { PageContent } from "@modules/layout/components/Page"
import useProvisioningOptions from "@modules/provisioning/hooks/useProvisioningOptions"

import { getPathToCreateSite } from "@modules/site/create/shared/utils"

const ProvisionSiteRoot = styled(`div`)`
  padding: ${p => p.theme.space[9]} 0;
  padding-bottom: 7.5rem;
`

function ProvisionSite({ organizationId, siteId, path }) {
  const pickCMS = path.includes(`cms`)
  const summary = path.includes(`summary`)
  const { provisioningOptions } = useProvisioningOptions(organizationId)

  const getCMSProvider = () => {
    if (!provisioningOptions) {
      return ""
    }
    return provisioningOptions.cmsProvider
  }

  const getActiveStep = () => {
    if (siteId) {
      if (summary) {
        return `summary`
      }
      return `connect`
    }

    if (pickCMS) {
      return `cms`
    }

    return `organization`
  }

  return (
    <ProvisionSiteRoot>
      <main>
        <Header />
        <ProvisionSiteTabs
          organizationId={organizationId}
          activeStep={getActiveStep()}
          path={path}
          siteId={siteId}
          cmsProvider={getCMSProvider()}
        />

        {!siteId && pickCMS && (
          <PageContent.Positioner
            css={theme => ({
              marginTop: theme.space[12],
            })}
          >
            <PickStarter organizationId={organizationId}>
              <Footer
                type="submit"
                cancel={() =>
                  navigate(`${getPathToCreateSite(organizationId)}?new_site=1`)
                }
                buttonText="Next"
                css={theme => ({
                  marginTop: theme.space[12],
                })}
              />
            </PickStarter>
          </PageContent.Positioner>
        )}
        {!siteId && !pickCMS && (
          <PickSourceTarget organizationId={organizationId} />
        )}
        {siteId && summary && (
          <Summary organizationId={organizationId} siteId={siteId} />
        )}
        {siteId && !summary && (
          <ConnectCms organizationId={organizationId} siteId={siteId} />
        )}
      </main>
    </ProvisionSiteRoot>
  )
}

ProvisionSite.propTypes = {
  siteId: PropTypes.string,
  path: PropTypes.string,
}

export default ProvisionSite
