import React, { Fragment, useState } from "react"
import { navigate } from "gatsby"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import CmsIntegrationItem from "@modules/cms/integration/components/CmsIntegrationItem"
import Loading from "@modules/ui/components/Loading"
import { Footer } from "@modules/site/create/shared/components/Footer"
import { provisionSite as text } from "@modules/locales/default.json"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"
import {
  ErrorAlert,
  useTriggerErrorAlert,
} from "@modules/ui/components/ErrorAlert"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import {
  useCmsIntegrationForProvisioningQuery,
  useFinalizeSiteProvisioningMutation,
  useProvisionedSiteDetailsQuery,
} from "@modules/site/create/Provision/queries.generated"
import { CmsVendor } from "@modules/graphql/types"
import useProvisioningOptions from "@modules/provisioning/hooks/useProvisioningOptions"

function CmsMessage({
  activeCmsIntegrationConfig,
  connected,
  nameWithOwner,
  name,
}) {
  if (name === `Contentful`) {
    return activeCmsIntegrationConfig &&
      activeCmsIntegrationConfig.totalorganizations &&
      activeCmsIntegrationConfig.organizationName ? (
      <span
        className={connected ? `connected` : `hide`}
        style={{ marginBottom: 10 }}
      >
        {text.contentfulSpace} {` `}
        <strong>{nameWithOwner.split(`/`)[1]}</strong> will be created in{` `}
        {activeCmsIntegrationConfig.totalorganizations > 1 ? (
          <span>
            the{` `}
            <strong>{activeCmsIntegrationConfig.orgName}</strong>
            {` `}
            {text.contentfulOrg}
          </span>
        ) : (
          text.contentfulDefaultOrg
        )}
        {` `}
        on your behalf.
      </span>
    ) : (
      <span
        className={connected ? `connected` : `hide`}
        style={{ marginBottom: 10 }}
      >
        {text.contentfulSpace} {` `}
        <strong>{nameWithOwner.split(`/`)[1]}</strong> will be created on your
        behalf.
      </span>
    )
  }

  return (
    <span
      className={connected ? `connected` : `hide`}
      style={{ marginBottom: 10 }}
    >
      {text.projectNamed} <strong>{nameWithOwner.split(`/`)[1]}</strong>
      {` `}
      {text.createdCms} {name} account
    </span>
  )
}

function ConnectCms({ organizationId, siteId }) {
  const {
    provisioningOptions,
    verifyProvisioningOptions,
  } = useProvisioningOptions(organizationId)
  const {
    data: cmsIntegrationsData,
    error: integrationError,
    loading: integrationLoading,
    refetch,
  } = useCmsIntegrationForProvisioningQuery({
    variables: {
      siteId,
      vendor: provisioningOptions
        ? provisioningOptions.cmsProvider
        : CmsVendor.Contentful,
    },
    fetchPolicy: `cache-and-network`,
  })
  const {
    data: provisionedSiteDetails,
    loading: provisionedSiteLoading,
    error: provisionedSiteError,
  } = useProvisionedSiteDetailsQuery({
    variables: { siteId },
    fetchPolicy: `cache-and-network`,
  })

  const [isLoading, setLoading] = useState(false)

  const [finalizeProvisioning] = useFinalizeSiteProvisioningMutation()
  const [setError, errorAlert] = useTriggerErrorAlert()

  // Make sure we have all provisioning information in session
  verifyProvisioningOptions()

  if (integrationLoading || provisionedSiteLoading) {
    return (
      <NarrowSingleColumn>
        <Loading
          delay={1000}
          message="Loading your repository..."
          bufferSize="padded"
        />
      </NarrowSingleColumn>
    )
  }

  if (integrationError || provisionedSiteError) {
    return (
      <NarrowSingleColumn>
        {[integrationError, provisionedSiteError].map((error, idx) => {
          return error ? (
            <ErrorAlert key={idx}>{error.message}</ErrorAlert>
          ) : null
        })}
      </NarrowSingleColumn>
    )
  }

  const cmsIntegrationForProvisioning =
    cmsIntegrationsData?.cmsIntegrationForProvisioning
  const {
    name,
    formattedName,
    logoUrl,
    connected,
  } = cmsIntegrationForProvisioning

  const repositoryFromSite = provisionedSiteDetails?.repositoryFromSite
  const { nameWithOwner } = repositoryFromSite
  const activeCmsIntegrationConfig =
    provisionedSiteDetails?.activeCmsIntegrationsForSite?.[0]?.config ?? {}

  return (
    <NarrowSingleColumn>
      {isLoading ? (
        <Loading
          delay={1000}
          message="Starting your project..."
          bufferSize="padded"
        />
      ) : (
        <Fragment>
          <WizardStepHeader title={text.connectCms}>
            {text.connectCmsDescription}
          </WizardStepHeader>
          <CmsIntegrationItem
            logoUrl={logoUrl}
            name={name}
            formattedName={formattedName}
            siteId={siteId}
            connected={connected}
            repository={repositoryFromSite}
            provision
            refetch={refetch}
            description={
              <Fragment>
                <CmsMessage
                  nameWithOwner={nameWithOwner}
                  connected={connected}
                  activeCmsIntegrationConfig={activeCmsIntegrationConfig}
                  name={formattedName}
                />
              </Fragment>
            }
          />
          {errorAlert}
          <Footer
            isSubmitting={isLoading}
            type="button"
            cancel={() =>
              navigate(`${getPathToProvisionSite(organizationId)}/organization`)
            }
            buttonText={connected ? `Start my site` : `Next`}
            submitForm={() => {
              if (!connected) {
                return setError({
                  message: `You must connect to ${formattedName} before you proceed.`,
                })
              }
              setLoading(true)
              return finalizeProvisioning({
                variables: {
                  siteId,
                  cmsTemplateId: provisioningOptions.cmsTemplateId,
                },
              })
                .then(({ data }) => {
                  setLoading(false)
                  if (!data.finalizeSiteProvisioning.success) {
                    return setError({
                      message: data.finalizeSiteProvisioning.message,
                    })
                  }
                  return navigate(
                    `${getPathToProvisionSite(
                      organizationId
                    )}/${siteId}/summary`
                  )
                })
                .catch(e => {
                  setLoading(false)
                  setError(e)
                })
            }}
          />
        </Fragment>
      )}
    </NarrowSingleColumn>
  )
}

export default ConnectCms
