import React from "react"

export default function({ ...rest }) {
  return (
    <svg
      width="39"
      height="17"
      viewBox="0 0 39 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M37.5163 0.211914H1.48365C0.781291 0.211914 0.211914 0.781291 0.211914 1.48365V14.625C0.211914 15.3273 0.781291 15.8967 1.48365 15.8967H37.5163C38.2186 15.8967 38.788 15.3273 38.788 14.625V1.48365C38.788 0.781291 38.2186 0.211914 37.5163 0.211914Z"
          fill="white"
          stroke="#EAEAEA"
          strokeWidth="0.423913"
        />
        <path
          d="M19.9238 10.1739V3.91016"
          stroke="black"
          strokeWidth="1.69565"
          strokeLinecap="square"
        />
        <path
          d="M16.1086 7.698L19.9552 11.5446L23.7464 7.75367"
          stroke="black"
          strokeWidth="1.69565"
        />
        <path
          d="M4.64893 12.4144V5.3594L8.56306 9.27381L12.5004 5.33679V12.3639"
          stroke="black"
          strokeWidth="1.69565"
        />
        <path
          d="M26.4411 11.6627L34.7127 3.3913M34.6022 11.6627L26.3306 3.3913L34.6022 11.6627Z"
          stroke="#F9AC00"
          strokeWidth="1.69565"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="39" height="16.1087" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
