import React from "react"
import styled from "@emotion/styled"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { EmphaticHeading as BaseEmphaticHeading } from "@modules/ui/typography"
import { createSite as text } from "@modules/locales/default.json"

const Heading = styled(BaseEmphaticHeading)`
  text-align: center;
`

export function Header() {
  return (
    <NarrowSingleColumn>
      <Heading as="h1">{text.title}</Heading>
    </NarrowSingleColumn>
  )
}
