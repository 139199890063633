import React from "react"

export default function({ height = 16, width = 40, ...rest }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 260 80"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x2="78.687"
        y1="40.44"
        y2="40.44"
      >
        <stop offset="0" stopColor="#ff593d" />
        <stop offset="1" stopColor="#ff7751" />
      </linearGradient>
      <path
        d="M39.344.844H0v79.193h39.344c19.677 0 39.343-17.729 39.343-39.591S59.021.844 39.344.844zm0 57.589a17.993 17.993 0 1 1 18-17.987 17.986 17.986 0 0 1-18 17.987z"
        fill="url(#a)"
      />
      <path
        d="M119.054.858c11.842 0 17.916 7.179 17.916 17.058v8.835c0 9.878-6.074 17.057-17.916 17.057h-18.775V.858zm7.363 17c0-5.46-2.884-8.344-9.265-8.344h-6.381v25.642h6.381c6.381 0 9.265-2.884 9.265-8.344zm43.241 17.977h-15.523l-2.393 7.977h-10.553L156.527.858h10.8l15.339 42.95h-10.554zm-12.946-8.467h10.369l-5.154-16.995zM216.033.858v8.9h-11.6v34.05h-10.55V9.755h-11.6V.858zm25.545 43.809c-13.007 0-19.695-8.345-19.695-19.573v-5.522C221.883 8.344 228.571 0 241.578 0s19.7 8.344 19.7 19.572v5.522c-.004 11.228-6.692 19.573-19.7 19.573zm0-35.894c-6.135 0-9.142 4.112-9.142 10.247v6.626c0 6.136 3.007 10.247 9.142 10.247s9.142-4.111 9.142-10.247V19.02c0-6.135-3.006-10.247-9.142-10.247zM109.69 75.244a4.813 4.813 0 0 0 5.2-4.868h5.954c-.075 5.542-4.156 10.186-11.084 10.186-8.088 0-11.982-5.093-11.982-11.908v-3.445c0-6.815 3.894-11.907 11.982-11.907 6.928 0 11.009 4.642 11.084 10.184h-5.954a4.813 4.813 0 0 0-5.2-4.868c-3.895 0-5.468 2.584-5.468 6.291v4.044c0 3.707 1.578 6.291 5.468 6.291zm37.055-21.419h5.917v26.212h-6.178V66.183l-4.12 8.012h-5.092l-4.081-8.012v13.854h-6.179V53.825h5.916l6.89 14.267zm22.765 26.737c-6.291 0-10.522-3-10.522-8.276v-.712h6.178v.526c0 2.209 1.423 3.557 4.381 3.557 2.659 0 4.419-1.086 4.419-3.108a2.151 2.151 0 0 0-1.8-2.321l-6.553-1.648c-4.082-1.011-6.179-2.808-6.179-7.077 0-5.205 3.707-8.2 9.923-8.2 5.879 0 9.624 3.182 9.624 7.975v.637h-5.951v-.449c0-1.761-.974-3.221-3.67-3.221-2.247 0-3.669.786-3.669 2.658a1.994 1.994 0 0 0 1.685 2.135l6.964 1.872c4.194 1.123 5.879 3.445 5.879 7.077 0 5.242-4.643 8.575-10.709 8.575z"
        fill="#ff7751"
      />
    </svg>
  )
}
