import React from "react"

export default function() {
  return (
    <svg
      className="dots"
      width="808"
      height="302"
      viewBox="0 0 808 302"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M343.004 204C345.216 204 347.009 205.79 347.009 208C347.009 210.21 345.216 212 343.004 212C340.792 212 339 210.21 339 208C339 205.79 340.792 204 343.004 204Z"
        fill="#DEF5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.007 84C135.693 84 133.005 86.6856 133.003 90C133.001 93.3144 135.686 96 139 96C142.315 96 145.003 93.3144 145.005 90C145.007 86.6856 142.322 84 139.007 84Z"
        fill="#FFF2A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M799.489 75.3337C795.07 75.3337 791.489 71.7529 791.489 67.3337C791.489 62.9145 795.07 59.3337 799.489 59.3337C803.908 59.3337 807.489 62.9145 807.489 67.3337C807.489 71.7529 803.908 75.3337 799.489 75.3337Z"
        fill="#DBF0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 302C-4.4192 302 -8 298.419 -8 294C-8 289.581 -4.4192 286 0 286C4.4192 286 8 289.581 8 294C8 298.419 4.4192 302 0 302Z"
        fill="#DBF0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299 8.82672C296.79 8.82672 295 7.03632 295 4.82672C295 2.61712 296.79 0.826722 299 0.826722C301.21 0.826722 303 2.61712 303 4.82672C303 7.03632 301.21 8.82672 299 8.82672Z"
        fill="#F6EDFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M715 178C712.79 178 711 179.79 711 182C711 184.21 712.79 186 715 186C717.21 186 719 184.21 719 182C719 179.79 717.21 178 715 178Z"
        fill="#FFF4DB"
      />
    </svg>
  )
}
