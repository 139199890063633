export const STARTER_TO_IMAGE = {
  CONTENTFUL: {
    Blog: `https://user-images.githubusercontent.com/18426780/61743978-7b0f4580-ad53-11e9-9b7a-38b9447c8020.png`,
  },
  DATOCMS: {
    Portfolio: `https://raw.githubusercontent.com/datocms/gatsby-portfolio/master/preview.png`,
    Snipcart: `https://rawgit.com/datocms/snipcart-gatsby-demo/master/preview.png?w=600`,
  },
  COSMICJS: {
    Blog: `https://s3-us-west-2.amazonaws.com/cosmicjs/d6276410-68c2-11e8-9105-b16ce5fe6091-Screenshot-4.png`,
  },
  NONE: {
    Blog: `https://www.gatsbyjs.org/Gatsby-Monogram.svg`,
  },
}
