/** @jsx jsx */
import { jsx } from "@emotion/core"
import React from "react"

import PropTypes from "prop-types"
import { css } from "@emotion/core"
import {
  BlogIcon,
  PortfolioIcon,
  EcommerceIcon,
  Text,
  Heading,
  BaseAnchor,
  Badge,
} from "gatsby-interface"
import { FaExternalLinkAlt } from "react-icons/fa"
import ContentfulLogo from "./ContentfulLogo"
import DatoCMSLogo from "@modules/assets/cdn/DatoCMS"
import MdxLogo from "@modules/assets/cdn/MdxLogo"
import CosmicjsLogo from "@modules/assets/cdn/CosmicjsLogo"
import {
  RadioButtonCard,
  useRadioButtonCardContext,
  RadioButtonCardInput,
} from "@modules/ui/components/RadioButtonCard"

const siteTypeIcons = {
  Blog: BlogIcon,
  Portfolio: PortfolioIcon,
  Snipcart: EcommerceIcon,
}

const cmsVendorIcons = {
  CONTENTFUL: ContentfulLogo,
  DATOCMS: DatoCMSLogo,
  MDX: MdxLogo,
  COSMICJS: CosmicjsLogo,
}

const IMAGE_HEIGHT = 180
const IMAGE_OFFSET = 20

const radioImageBaseCss = theme => ({
  backgroundRepeat: "no-repeat",
  top: -IMAGE_OFFSET,
  margin: `0 auto -${IMAGE_OFFSET}px`,
  position: "relative",
  width: "100%",
  height: IMAGE_HEIGHT,
  borderRadius: theme.radii[2],
  transition: "all ease-out 0.2s",
})

function RadioImage({ image }) {
  const { highlighted, disabled } = useRadioButtonCardContext()

  return (
    <div
      css={theme => [
        radioImageBaseCss(theme),
        disabled
          ? css({
              display: `none`,
            })
          : css({
              backgroundImage: `url(${image})`,
              backgroundSize: `cover`,
              backgroundPositionY: highlighted ? 0 : `20px`,
              boxShadow: highlighted
                ? `0px 4px 8px rgba(102, 51, 153, 0.08), 0px 8px 16px rgba(102, 51, 153, 0.16)`
                : `none`,
            }),
      ]}
    />
  )
}

const badgeCss = theme => ({
  alignSelf: `flex-start`,
  margin: `${theme.space[6]} 0`,
})

const disabledTextCss = theme => ({
  color: theme.colors.grey[60],
})

const detailLinkCss = theme => ({
  marginLeft: theme.space[3],
})

function StarterInfo({ title, description, selected, disabled, detailLink }) {
  return (
    <React.Fragment>
      <Heading
        as="span"
        css={theme => [
          {
            marginTop: theme.space[7],
            fontSize: theme.fontSizes[3],
            display: `flex`,
          },
          selected && {
            color: theme.colors.gatsby,
          },
          disabled && disabledTextCss(theme),
        ]}
      >
        {detailLink ? (
          <BaseAnchor
            href={`https://www.gatsbyjs.org/starters/${detailLink}`}
            target="_blank"
            css={theme => [
              { textDecoration: "none" },
              disabled && disabledTextCss(theme),
            ]}
          >
            {title}
            {` `}
            <FaExternalLinkAlt css={detailLinkCss} />
          </BaseAnchor>
        ) : (
          title
        )}
      </Heading>
      <Text
        css={theme => [
          {
            marginTop: theme.space[5],
            marginBottom: 0,
            fontSize: theme.fontSizes[1],
            color: theme.colors.grey[70],
          },
          disabled && disabledTextCss(theme),
        ]}
      >
        {description}
      </Text>
    </React.Fragment>
  )
}

function StarterCmsIcon({ cmsVendor, checked }) {
  const { disabled, hovered } = useRadioButtonCardContext()
  const CmsVendorIcon =
    cmsVendor && cmsVendorIcons[cmsVendor] ? cmsVendorIcons[cmsVendor] : null

  if (!CmsVendorIcon) {
    return null
  }

  return (
    <CmsVendorIcon
      css={{
        height: `22px`,
        width: `auto`,
        filter: (checked || hovered) && !disabled ? `` : `grayscale(100%)`,
        opacity: checked ? 1 : 0.5,
      }}
    />
  )
}

export default function StarterRadioCard({
  value,
  image,
  field,
  title,
  description,
  siteType,
  cmsVendor,
  comingSoon,
  detailLink,
  getOptionControlProps,
  getOptionLabelProps,
}) {
  const SiteTypeIcon =
    siteType && siteTypeIcons[siteType] ? siteTypeIcons[siteType] : null

  const checked = field.value === value
  const disabled = comingSoon

  return (
    <RadioButtonCard
      name={field.name}
      checked={checked}
      disabled={disabled}
      css={{ paddingTop: 0 }}
      data-cy={`field-${cmsVendor}-${siteType}`}
    >
      <React.Fragment>
        {comingSoon && (
          <div
            data-cy="coming-soon"
            css={{
              flex: `0 0 ${IMAGE_HEIGHT - IMAGE_OFFSET}px`,
            }}
          >
            <Badge tone="NEUTRAL" css={badgeCss}>
              Coming soon
            </Badge>
          </div>
        )}
        {image && <RadioImage image={image} />}

        <label
          {...getOptionLabelProps(value)}
          css={{ cursor: `pointer`, flexGrow: 1 }}
        >
          <StarterInfo
            title={title}
            description={description}
            selected={checked}
            disabled={disabled}
            detailLink={detailLink}
          />
        </label>
        <div
          css={theme => ({
            display: `flex`,
            alignItems: `center`,
            marginTop: theme.space[7],
          })}
        >
          {SiteTypeIcon && (
            <SiteTypeIcon
              css={theme => ({
                marginRight: theme.space[5],
                color: theme.colors.grey[40],
              })}
            />
          )}
          <StarterCmsIcon cmsVendor={cmsVendor} checked={checked} />
          <div css={{ position: `relative`, marginLeft: "auto" }}>
            <RadioButtonCardInput
              value={value}
              name={field.name}
              checked={checked}
              onChange={field.onChange}
              {...getOptionControlProps(value)}
            />
          </div>
        </div>
      </React.Fragment>
    </RadioButtonCard>
  )
}

StarterRadioCard.propTypes = {
  value: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  siteType: PropTypes.oneOf([`Blog`, `Portfolio`, `Snipcart`, `Default`])
    .isRequired,
  cmsVendor: PropTypes.oneOf([
    `CONTENTFUL`,
    `DATOCMS`,
    `COSMICJS`,
    `MDX`,
    `NONE`,
  ]).isRequired,
  comingSoon: PropTypes.bool,
  field: PropTypes.object.isRequired,
}
