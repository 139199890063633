import React from "react"
import { navigate } from "gatsby"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"

const PROVISIONING_OPTIONS_STORAGE_KEY = `cmsProvisioningOptions`

export default function useProvisioningOptions(organizationId) {
  const optionsJson =
    window && window.sessionStorage.getItem(PROVISIONING_OPTIONS_STORAGE_KEY)
  const options = React.useMemo(() => JSON.parse(optionsJson), [optionsJson])

  const writeProvisioningOptions = ({
    starter,
    cmsProvider,
    cmsTemplateId,
    cmsProviderLogo,
  }) => {
    window.sessionStorage.setItem(
      PROVISIONING_OPTIONS_STORAGE_KEY,
      JSON.stringify({
        cmsTemplateId,
        starter,
        cmsProvider,
        cmsProviderLogo,
      })
    )
  }

  const navigateToProvisioning = () => {
    const provisioningPath = `${getPathToProvisionSite(organizationId)}/cms`

    if (typeof window === "undefined") {
      return
    }

    const alreadyNavigated = window.location.pathname.includes(provisioningPath)

    if (!alreadyNavigated) {
      navigate(provisioningPath)
    }
  }

  const verifyProvisioningOptions = () => {
    if (options) {
      const { starter, cmsProvider, cmsTemplateId, cmsProviderLogo } = options
      if (!starter || !cmsProvider || !cmsTemplateId || !cmsProviderLogo) {
        navigateToProvisioning()
        return null
      }
      return
    }
    navigateToProvisioning()
    return null
  }

  return {
    provisioningOptions: options,
    writeProvisioningOptions,
    verifyProvisioningOptions,
  }
}
