export const OrgTargetTypes = {
  PersonalAccount: `User`,
  AuthorizedOrganization: `Organization`,
}

// This enum captures all variants for which source-control targets they've
// connected:
// - NotConnectedYet hasn't connected anything
// - PersonalUser has only connected their personal GitHub account to Gatsby
// - SingleOrgMember has only connected 1 GitHub org to Gatsby
// - MultipleTargets has connected at least 2 targets (may or may not include
//   the user's personal account)
export const ConnectedSourceTargetVariants = {
  NotConnectedYet: `not-connected-yet`,
  PersonalUser: `personal-user`,
  SingleOrgMember: `single-org-member`,
  MultipleTargets: `multiple-targets`,
}
