import gql from "graphql-tag"

export const CMS_VENDORS_QUERY = gql`
  query cmsVendors {
    availableCmsProvisioningVendors {
      id
      name
      formattedName
      logoUrl
      description
      provisioningEnabled
    }
  }
`
