import React from "react"
import PropTypes from "prop-types"
import { provisionSite as text } from "@modules/locales/default.json"
import { StepIndicator, StepIndicatorStep } from "gatsby-interface"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"

function ProvisionSiteTabs({
  organizationId,
  siteId,
  activeStep = `cms`,
  cmsProvider = ``,
  path,
}) {
  return (
    <StepIndicator>
      <StepIndicatorStep
        to={
          path.includes(`cms`)
            ? ``
            : `${getPathToProvisionSite(organizationId)}/cms`
        }
        status={activeStep === `cms` ? `ACTIVE` : `DONE`}
      >
        {text.starterTab}
      </StepIndicatorStep>
      <StepIndicatorStep
        to={
          activeStep === `summary` || activeStep === `connect`
            ? `${getPathToProvisionSite(organizationId)}/organization`
            : ``
        }
        status={
          activeStep === `organization`
            ? `ACTIVE`
            : activeStep === `connect` || activeStep === `summary`
            ? `DONE`
            : `DEFAULT`
        }
      >
        {text.repositoryTab}
      </StepIndicatorStep>
      {cmsProvider !== `NONE` && (
        <StepIndicatorStep
          to={
            activeStep === `summary`
              ? `${getPathToProvisionSite(organizationId)}/${siteId}/connect`
              : ``
          }
          status={
            activeStep === `connect`
              ? `ACTIVE`
              : activeStep === `summary`
              ? `DONE`
              : `DEFAULT`
          }
        >
          {text.connectCmsTab}
        </StepIndicatorStep>
      )}
      <StepIndicatorStep
        status={activeStep === `summary` ? `ACTIVE` : `DEFAULT`}
      >
        {text.summaryTab}
      </StepIndicatorStep>
    </StepIndicator>
  )
}

ProvisionSiteTabs.propTypes = {
  activeStep: PropTypes.oneOf([`cms`, `organization`, `connect`, `summary`]),
  cmsProvider: PropTypes.string,
  path: PropTypes.string,
}

export default ProvisionSiteTabs
