import React, { useState } from "react"
import { CmsAuthorizationModal } from "@modules/cms/authorization/components/CmsAuthorizationModal"
import { OnboardingSiteType } from "@modules/onboarding/shared/constants"
import { CardSection, Card, CardHeader, Text, Button } from "gatsby-interface"
import { useTracker } from "@modules/analytics"
import BoltIcon from "@modules/assets/icons/BoltIcon"
import { Integration } from "@modules/ui/components/Integration"
import { cmsIntegrations as text } from "@modules/locales/default.json"

export default function CmsIntegrationItem({
  logoUrl,
  name,
  formattedName,
  description,
  siteId,
  repository,
  connected,
  provision = false,
  refetch,
}) {
  const { trackButtonClicked } = useTracker()
  const [isOpenedModal, toggleModal] = useState(false)

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title={
            <div>
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt={name}
                  css={{ maxHeight: `32px`, margin: 0 }}
                />
              )}
            </div>
          }
        >
          {!connected ? (
            <Button
              variant="SECONDARY"
              type="button"
              data-cy={
                connected
                  ? "edit-cms-integration-button-" + name
                  : "connect-cms-integration-button-" + name
              }
              onClick={() => {
                trackButtonClicked(
                  connected ? `Edit ${name}` : `Connect ${name}`,
                  {
                    siteId: siteId,
                    uiSource: `New Site Wizard: integrations`,
                  }
                )

                return toggleModal(true)
              }}
              rightIcon={<BoltIcon />}
            >
              {text.connect}
            </Button>
          ) : (
            <Integration.Status connected />
          )}
        </CardHeader>

        <CardSection applyPaddingTop={false}>
          {description && <Text noMarginBottom>{description}</Text>}
        </CardSection>
      </Card>

      {isOpenedModal && (
        <CmsAuthorizationModal
          closeModal={() => toggleModal(false)}
          siteId={siteId}
          formattedName={formattedName}
          isConnected={!!connected}
          orgSiteName={repository.nameWithOwner}
          name={name}
          type={
            provision ? OnboardingSiteType.Provision : OnboardingSiteType.Create
          }
          refetch={refetch}
          isOpen={isOpenedModal}
        />
      )}
    </React.Fragment>
  )
}
