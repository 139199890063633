import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
  RadioButtonFieldBlock,
  useConnectedField,
  BaseAnchor,
} from "gatsby-interface"
import { useTracker } from "@modules/analytics"
import { OrgTargetTypes } from "@modules/organization/shared/constants"

const REPOSITORY_SELECTION_ALL = `all`

export function SourceInstallationField({ organizations, name, ...rest }) {
  const { trackButtonClicked } = useTracker()
  const [{ value: currentValue, ...connectedProps }] = useConnectedField(name)

  const selectedOrganization = React.useMemo(() => {
    return organizations.find(({ id }) => id === currentValue)
  }, [currentValue, organizations])

  const repositorySelection = selectedOrganization?.repositorySelection
  const showPermissionsHint =
    repositorySelection && repositorySelection !== REPOSITORY_SELECTION_ALL

  return (
    <RadioButtonFieldBlock
      name={name}
      hint={
        showPermissionsHint && (
          <React.Fragment>
            Gatsby requires permissions to create and manage future repositories
            on your GitHub account. Please go to your{" "}
            <BaseAnchor href={selectedOrganization.settingsUrl} target="_blank">
              installation settings page
            </BaseAnchor>
            {` `}
            and enable &#34;All repositories &#34; access.
          </React.Fragment>
        )
      }
      value={currentValue}
      css={{
        maxHeight: `25rem`,
        overflowX: `hidden`,
        overflowY: `auto`,
      }}
      variant="framed"
      {...connectedProps}
      {...rest}
      options={organizations.map(organization => {
        return {
          value: organization.id,
          label: (
            <SourceOrganizationOptionLabel
              name={organization.name}
              targetType={organization.targetType}
            />
          ),
          onClick: () => {
            trackButtonClicked(`Installation List Item`, {
              repositoryId: organization.id,
              name: organization.name,
            })
          },
        }
      })}
    />
  )
}

SourceInstallationField.propTypes = {
  name: PropTypes.string.isRequired,
  organizations: PropTypes.array,
}

function SourceOrganizationOptionLabel({ name, targetType }) {
  const isPersonalAccount = targetType === OrgTargetTypes.PersonalAccount

  return (
    <Fragment>
      {name}
      {isPersonalAccount && (
        <span css={theme => ({ color: theme.colors.grey[50] })}>
          &nbsp;&mdash;&nbsp;Your personal GitHub account
        </span>
      )}
    </Fragment>
  )
}
