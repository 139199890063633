/** @jsx jsx */
import { jsx } from "@emotion/core"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import { navigate } from "gatsby"
import { Form as FormikForm, Field } from "formik"
import {
  FormFieldset,
  FormLegend,
  Heading,
  useAriaFormGroupField,
} from "gatsby-interface"

import Form from "@modules/form/components/Form"
import { processLoginAttempt } from "../../../auth"
import Loading from "@modules/ui/components/Loading"
import StarterRadioCard from "./StarterRadioCard"
import { CMS_VENDORS_QUERY } from "../../shared/queries"
import useProvisioningOptions from "../../../provisioning/hooks/useProvisioningOptions"
import { recordCreateSite } from "../../../fullStory"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"

const startersCss = theme => ({
  display: `grid`,
  maxWidth: `60rem`,
  gridTemplateColumns: `repeat(auto-fill, minmax(240px, 1fr));`,
  margin: `${theme.space[10]} auto 0`,
  gap: `${theme.space[9]} ${theme.space[8]}`,
  [theme.mediaQueries.mobile]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(290px, 1fr));`,
  },
})

const CMS_STARTERS_QUERY = gql`
  query availableCmsStarters {
    availableCmsStarters {
      id
      type
      description
      available
      starterImageUrl
      projectSlug
      vendor
      formattedVendorName
    }
  }
`

function PickStarter({
  organizationId,
  authenticated = true,
  children,
  heading,
  siteType,
}) {
  const {
    provisioningOptions,
    writeProvisioningOptions,
  } = useProvisioningOptions(organizationId)
  const { data: vendorsData, loading: vendorsLoading } = useQuery(
    CMS_VENDORS_QUERY
  )
  const { data: startersData, loading: startersLoading } = useQuery(
    CMS_STARTERS_QUERY,
    {
      fetchPolicy: `cache-first`,
    }
  )
  const {
    getLegendProps,
    getOptionControlProps,
    getOptionLabelProps,
  } = useAriaFormGroupField(`starterRadio`, { required: true })

  if (vendorsLoading || startersLoading) {
    return null
  }

  const vendors = vendorsData.availableCmsProvisioningVendors || []
  const starters = startersData.availableCmsStarters || []

  if (
    (vendors && vendors.length === 0) ||
    (starters && starters.length === 0)
  ) {
    return <p>There is an issue.</p>
  }

  let initialStarter = starters[0]
  if (provisioningOptions && provisioningOptions.starter) {
    initialStarter =
      starters.find(
        ({ projectSlug }) => projectSlug === provisioningOptions.starter
      ) || initialStarter
  }

  const getStarterTitle = (type, formattedVendorName) => {
    // Bandaid - we don't want to display "Cosmic JS" on the starters
    if (formattedVendorName.includes(`Cosmic JS`)) {
      formattedVendorName = `Cosmic`
    }

    return formattedVendorName.toLowerCase() === `none`
      ? `${type}`
      : `${type} with ${formattedVendorName}`
  }

  return (
    <Form
      onSubmit={values => {
        const starter = starters.find(({ id }) => id === values.starter)
        const vendor = vendors.find(({ name }) => name === starter.vendor)

        writeProvisioningOptions({
          starter: starter.projectSlug,
          cmsTemplateId: starter.type,
          cmsProvider: vendor.name,
          cmsProviderLogo: vendor.logoUrl,
        })

        recordCreateSite()

        const basePathToProvision = getPathToProvisionSite(organizationId)

        if (authenticated) {
          return navigate(`${basePathToProvision}/organization`)
        }

        let loginRedirectUrl = `${basePathToProvision}/organization`
        if (siteType) {
          loginRedirectUrl = `${loginRedirectUrl}?site_type=${siteType}`
        }

        return processLoginAttempt(
          `?loginRedirectUrl=${encodeURIComponent(loginRedirectUrl)}`
        )
      }}
      initialValues={{
        starter: initialStarter.id,
      }}
    >
      {({ isSubmitting }) =>
        isSubmitting ? (
          <Loading
            delay={300}
            message="Connecting your starter..."
            bufferSize="padded"
          />
        ) : (
          <FormikForm>
            {heading && (
              <Heading
                css={theme => ({
                  textAlign: `center`,
                  marginTop: theme.space[12],
                })}
              >
                {heading}
              </Heading>
            )}

            <FormFieldset>
              <FormLegend
                {...getLegendProps("Pick a starter")}
                css={visuallyHiddenCss}
              />

              <Field name="starter">
                {({ field }) => (
                  <div css={startersCss}>
                    {starters.map(starter => (
                      <StarterRadioCard
                        key={starter.id}
                        value={starter.id}
                        title={getStarterTitle(
                          starter.type,
                          starter.formattedVendorName
                        )}
                        image={starter.starterImageUrl}
                        field={field}
                        description={starter.description}
                        comingSoon={!starter.available}
                        siteType={starter.type}
                        cmsVendor={starter.vendor}
                        detailLink={starter.projectSlug}
                        getOptionControlProps={getOptionControlProps}
                        getOptionLabelProps={getOptionLabelProps}
                      />
                    ))}
                  </div>
                )}
              </Field>
            </FormFieldset>
            {children}
          </FormikForm>
        )
      }
    </Form>
  )
}

export default PickStarter
