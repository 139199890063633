export default function formatRepoName(input) {
  /**
   * NOTE: This function is copy/pasted from:
   * cloud/services/graphql-gateway/src/utils/formatRepoName.ts
   *
   * Please be sure to make changes in both places.
   */
  const withoutSpecialChars = input.toLowerCase().replace(/[\W_]+/g, ` `)

  return withoutSpecialChars.split(` `).join(`-`)
}
