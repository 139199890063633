// THIS IS A GENERATED FILE
import * as Types from "../../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type CmsIntegrationForProvisioningQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
  vendor?: Types.Maybe<Types.CmsVendor>
}>

export type CmsIntegrationForProvisioningQuery = { __typename?: "Query" } & {
  cmsIntegrationForProvisioning?: Types.Maybe<
    { __typename?: "CmsProvider" } & Pick<
      Types.CmsProvider,
      "id" | "name" | "formattedName" | "logoUrl" | "connected"
    >
  >
}

export type FinalizeSiteProvisioningMutationVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
  cmsTemplateId: Types.Scalars["String"]
}>

export type FinalizeSiteProvisioningMutation = { __typename?: "Mutation" } & {
  finalizeSiteProvisioning?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message" | "errorType"
    >
  >
}

export type ProvisionedSiteDetailsQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
}>

export type ProvisionedSiteDetailsQuery = { __typename?: "Query" } & {
  repositoryFromSite?: Types.Maybe<
    { __typename?: "Repository" } & Pick<
      Types.Repository,
      "id" | "nameWithOwner"
    >
  >
  siteDetails?: Types.Maybe<
    { __typename?: "Site" } & Pick<
      Types.Site,
      "id" | "organizationId" | "buildsEnabled"
    >
  >
  activeCmsIntegrationsForSite?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "CmsIntegration" } & Pick<
          Types.CmsIntegration,
          "id" | "resourceId" | "vendor" | "config"
        >
      >
    >
  >
}

export const CmsIntegrationForProvisioningDocument = gql`
  query CmsIntegrationForProvisioning($siteId: UUID!, $vendor: CmsVendor) {
    cmsIntegrationForProvisioning(siteId: $siteId, vendor: $vendor) {
      id
      name
      formattedName
      logoUrl
      connected
    }
  }
`

/**
 * __useCmsIntegrationForProvisioningQuery__
 *
 * To run a query within a React component, call `useCmsIntegrationForProvisioningQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsIntegrationForProvisioningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsIntegrationForProvisioningQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useCmsIntegrationForProvisioningQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CmsIntegrationForProvisioningQuery,
    CmsIntegrationForProvisioningQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CmsIntegrationForProvisioningQuery,
    CmsIntegrationForProvisioningQueryVariables
  >(CmsIntegrationForProvisioningDocument, baseOptions)
}
export function useCmsIntegrationForProvisioningLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CmsIntegrationForProvisioningQuery,
    CmsIntegrationForProvisioningQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CmsIntegrationForProvisioningQuery,
    CmsIntegrationForProvisioningQueryVariables
  >(CmsIntegrationForProvisioningDocument, baseOptions)
}
export type CmsIntegrationForProvisioningQueryHookResult = ReturnType<
  typeof useCmsIntegrationForProvisioningQuery
>
export type CmsIntegrationForProvisioningLazyQueryHookResult = ReturnType<
  typeof useCmsIntegrationForProvisioningLazyQuery
>
export type CmsIntegrationForProvisioningQueryResult = ApolloReactCommon.QueryResult<
  CmsIntegrationForProvisioningQuery,
  CmsIntegrationForProvisioningQueryVariables
>
export const FinalizeSiteProvisioningDocument = gql`
  mutation FinalizeSiteProvisioning($siteId: UUID!, $cmsTemplateId: String!) {
    finalizeSiteProvisioning(siteId: $siteId, cmsTemplateId: $cmsTemplateId) {
      success
      message
      errorType
    }
  }
`
export type FinalizeSiteProvisioningMutationFn = ApolloReactCommon.MutationFunction<
  FinalizeSiteProvisioningMutation,
  FinalizeSiteProvisioningMutationVariables
>

/**
 * __useFinalizeSiteProvisioningMutation__
 *
 * To run a mutation, you first call `useFinalizeSiteProvisioningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeSiteProvisioningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeSiteProvisioningMutation, { data, loading, error }] = useFinalizeSiteProvisioningMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      cmsTemplateId: // value for 'cmsTemplateId'
 *   },
 * });
 */
export function useFinalizeSiteProvisioningMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinalizeSiteProvisioningMutation,
    FinalizeSiteProvisioningMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    FinalizeSiteProvisioningMutation,
    FinalizeSiteProvisioningMutationVariables
  >(FinalizeSiteProvisioningDocument, baseOptions)
}
export type FinalizeSiteProvisioningMutationHookResult = ReturnType<
  typeof useFinalizeSiteProvisioningMutation
>
export type FinalizeSiteProvisioningMutationResult = ApolloReactCommon.MutationResult<
  FinalizeSiteProvisioningMutation
>
export type FinalizeSiteProvisioningMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinalizeSiteProvisioningMutation,
  FinalizeSiteProvisioningMutationVariables
>
export const ProvisionedSiteDetailsDocument = gql`
  query ProvisionedSiteDetails($siteId: UUID!) {
    repositoryFromSite(siteId: $siteId) {
      id
      nameWithOwner
    }
    siteDetails(id: $siteId) {
      id
      organizationId
      buildsEnabled
    }
    activeCmsIntegrationsForSite(siteId: $siteId) {
      id
      resourceId
      vendor
      config
    }
  }
`

/**
 * __useProvisionedSiteDetailsQuery__
 *
 * To run a query within a React component, call `useProvisionedSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvisionedSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvisionedSiteDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useProvisionedSiteDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProvisionedSiteDetailsQuery,
    ProvisionedSiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ProvisionedSiteDetailsQuery,
    ProvisionedSiteDetailsQueryVariables
  >(ProvisionedSiteDetailsDocument, baseOptions)
}
export function useProvisionedSiteDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProvisionedSiteDetailsQuery,
    ProvisionedSiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ProvisionedSiteDetailsQuery,
    ProvisionedSiteDetailsQueryVariables
  >(ProvisionedSiteDetailsDocument, baseOptions)
}
export type ProvisionedSiteDetailsQueryHookResult = ReturnType<
  typeof useProvisionedSiteDetailsQuery
>
export type ProvisionedSiteDetailsLazyQueryHookResult = ReturnType<
  typeof useProvisionedSiteDetailsLazyQuery
>
export type ProvisionedSiteDetailsQueryResult = ApolloReactCommon.QueryResult<
  ProvisionedSiteDetailsQuery,
  ProvisionedSiteDetailsQueryVariables
>
