import React from "react"

export default function() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#8a4baf"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.48 20V13.74H8L13 4V10.26H16.35L11.48 20Z" />
    </svg>
  )
}
