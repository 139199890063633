/**
 * The "PickSourceTarget" component got pretty big and hairy,
 * so it's been split up into two components:
 *
 * - PickSourceTarget manages data-fetching and alternate
 *   (empty / warning / edge) states
 *
 * - PickSourceTargetContents renders the UI and manages
 *   transient state
 *
 * This is somewhat similar to the container/presentational
 * pattern; this component is the presentational one.
 */
import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useField } from "formik"
import { Button, InputConnectedField, Notification } from "gatsby-interface"
import { provisionSite as text } from "@modules/locales/default.json"
import { Footer } from "@modules/site/create/shared/components/Footer"
import { SourceInstallationField } from "@modules/organization/shared/components/SourceInstallationField"
import { ConnectedSourceTargetVariants } from "@modules/organization/shared/constants"
import { FadeOnMount } from "@modules/animation"
import formatRepoName from "@modules/toolkit/helpers/formatRepoName"
import {
  getVariantFromSourceControlData,
  getCopyForAddTarget,
} from "./PickSourceTarget.helpers"
import { getPathToProvisionSite } from "@modules/site/create/shared/utils"

const groupCss = theme => ({ display: `grid`, gridGap: theme.space[7] })

function AddTargetBlurb({ variant, currentSourceTarget, onAddOrganization }) {
  const copy = getCopyForAddTarget(variant, currentSourceTarget)

  if (!copy) {
    return null
  }

  return (
    <Notification
      variant="SECONDARY"
      tone="NEUTRAL"
      content={
        <div>
          {copy}
          {` `}
          <Button
            variant="GHOST"
            css={theme => ({
              padding: `0`,
              textDecoration: `underline`,
              fontFamily: theme.fonts.system,
              fontSize: "inherit",

              ":hover": {
                background: "transparent",
              },
            })}
            onClick={e => {
              e.preventDefault()
              onAddOrganization()
            }}
          >
            {text.addIt}
          </Button>
        </div>
      }
    />
  )
}

function RepoNameField(props) {
  const [_field, _meta, helpers] = useField("name")
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <InputConnectedField
      {...props}
      ref={inputRef}
      onChange={e => {
        helpers.setValue(formatRepoName(e.target.value))
      }}
      // This zIndex is needed to prevent input visually colliding with the fixed footer
      css={{ zIndex: 0 }}
    />
  )
}

function PickSourceTargetContents({
  organizationId,
  sourceTargets,
  currentSourceTarget,
  onAddOrganization,
  repoName,
}) {
  const variant = getVariantFromSourceControlData(sourceTargets)
  const labelSize = `L`

  return (
    <React.Fragment>
      <div css={groupCss}>
        <RepoNameField
          name="name"
          label={text.nameYourProject}
          labelSize={labelSize}
          data-cy="new-project-name"
        />

        {Boolean(repoName) && (
          <FadeOnMount css={groupCss}>
            {variant === ConnectedSourceTargetVariants.MultipleTargets && (
              <SourceInstallationField
                name="organization"
                label={text.chooseDestination}
                labelSize={labelSize}
                organizations={sourceTargets}
              />
            )}

            {sourceTargets.length > 0 && (
              <React.Fragment>
                <AddTargetBlurb
                  variant={variant}
                  currentSourceTarget={currentSourceTarget}
                  onAddOrganization={onAddOrganization}
                />

                <Footer
                  type="submit"
                  cancel={() =>
                    navigate(`${getPathToProvisionSite(organizationId)}/cms`)
                  }
                  buttonText="Next"
                />
              </React.Fragment>
            )}
          </FadeOnMount>
        )}
      </div>
    </React.Fragment>
  )
}

PickSourceTargetContents.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
}

export default PickSourceTargetContents
